import { createStore } from "vuex"
import { app } from "./app.js"
import { auth } from "./auth.js"
import { audits } from "./audits.js"
import { info } from "./info.js"
import { notifications } from "./notifications.js"
import { rooms } from "./rooms.js"
import { transactions } from "./transactions.js"
import { users } from "./users.js"
const store = createStore({
	modules: {
		app,
		auth,
		audits,
		info,
		notifications,
		rooms,
		transactions,
		users
	},
	// enable strict mode (adds overhead!)
	// for dev mode only
	//strict: process.env.DEV
});
export default store;