
export const AppMenus = {

  navbarTopRightItems: [],
  navbarTopLeftItems: [],
  navbarSideLeftItems: [
    {
      "to": "/transactions",
      "label": "Transactions Report",
      "icon": "pi pi-money-bill",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/rooms/categories",
      "label": "Book a Room",
      "icon": "pi pi-calendar",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/rooms",
      "label": "Manage Rooms",
      "icon": "pi pi-home",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/notifications",
      "label": "Notifications",
      "icon": "pi pi-bell",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/notifications/active",
      "label": "My Notifications",
      "icon": "pi pi-bell",
      "iconcolor": "",
      "target": "notification",
    },
    {
      "to": "/users",
      "label": "User Accounts",
      "icon": "pi pi-users",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/users/bookings",
      "label": "View Bookings",
      "icon": "pi pi-calendar",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/users/approvedbookings",
      "label": "Approved Bookings",
      "icon": "pi pi-check-circle",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/users/roomallocations",
      "label": "Room Allocations",
      "icon": "pi pi-key",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/info/edit/app",
      "label": "Manage App",
      "icon": "pi pi-cog",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/audits",
      "label": "Audit Trail",
      "icon": "pi pi-database",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/account",
      "label": "My Account",
      "icon": "pi pi-user",
      "iconcolor": "",
      "target": "",
    },
    {
      "to": "/custom/contact",
      "label": "Contact Us",
      "icon": "pi pi-phone",
      "iconcolor": "",
      "target": "",
    },
  ],
  statusItems: [
    { value: "Sent", label: "Sent" },
    { value: "Opened", label: "Opened" }
  ],
  paymentMethodItems: [
    { value: "MOBILE_MONEY", label: "MOBILE MONEY" },
    { value: "DEBIT_CARD", label: "DEBIT CARD" }
  ],
  networkItems: [
    { value: "MTN", label: "MTN" },
    { value: "AIRTELTIGO", label: "AIRTELTIGO" },
    { value: "VODAFONE", label: "VODAFONE" }
  ],
  accountStatusItems: [
    { value: "Active", label: "Active" },
    { value: "Blocked", label: "Blocked" }
  ],
  userTypeItems: [
    { value: "Admin", label: "Admin" },
    { value: "Porter", label: "Porter" },
    { value: "Student", label: "Student" }
  ],
  bookingStatusItems: [
    { value: "Approved", label: "Approve" },
    { value: "Declined", label: "Decline" }
  ],
  genderItems: [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" }
  ],
  year_of_studyItems: [
    { value: "1st Year", label: "1st Year" },
    { value: "2nd Year", label: "2nd Year" },
    { value: "3rd Year", label: "3rd Year" },
    { value: "4th Year", label: "4th Year" },
    { value: "5th Year", label: "5th Year" },
    { value: "6th Year", label: "6th Year" },
    { value: "7th Year", label: "7th Year" },
  ],
  expiry_monthItems: [
    { value: "01", label: "01" },
    { value: "02", label: "02" },
    { value: "03", label: "03" },
    { value: "04", label: "04" },
    { value: "05", label: "05" },
    { value: "06", label: "06" },
    { value: "07", label: "07" },
    { value: "08", label: "08" },
    { value: "09", label: "09" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" }
  ],
  expiry_yearItems: [
    { value: "35", label: "2035" },
    { value: "34", label: "2034" },
    { value: "33", label: "2033" },
    { value: "32", label: "2032" },
    { value: "31", label: "2031" },
    { value: "30", label: "2030" },
    { value: "29", label: "2029" },
    { value: "28", label: "2028" },
    { value: "27", label: "2027" },
    { value: "26", label: "2026" },
    { value: "25", label: "2025" },
    { value: "24", label: "2024" },
    { value: "23", label: "2023" },
    { value: "22", label: "2022" },
    { value: "21", label: "2021" }
  ],
  transactionsDateCreatedItems: [{ "label": "Today", "value": "Today" }, { "label": "Yesterday", "value": "Yesterday" }, { "label": "Last 7 Days", "value": "Last 7 Days" }, { "label": "Last 30 Days", "value": "Last 30 Days" }, { "label": "Last 60 Days", "value": "Last 60 Days" }, { "label": "Last 90 Days", "value": "Last 90 Days" }],

  countryItems: [
    { "value": "Ghana", "label": "Ghana" },
    { "value": "Afghanistan", "label": "Afghanistan" },
    { "value": "Aland Islands", "label": "Aland Islands" },
    { "value": "Albania", "label": "Albania" },
    { "value": "Algeria", "label": "Algeria" },
    { "value": "American Samoa", "label": "American Samoa" },
    { "value": "AndorrA", "label": "AndorrA" },
    { "value": "Angola", "label": "Angola" },
    { "value": "Anguilla", "label": "Anguilla" },
    { "value": "Antarctica", "label": "Antarctica" },
    { "value": "Antigua and Barbuda", "label": "Antigua and Barbuda" },
    { "value": "Argentina", "label": "Argentina" },
    { "value": "Armenia", "label": "Armenia" },
    { "value": "Aruba", "label": "Aruba" },
    { "value": "Australia", "label": "Australia" },
    { "value": "Austria", "label": "Austria" },
    { "value": "Azerbaijan", "label": "Azerbaijan" },
    { "value": "Bahamas", "label": "Bahamas" },
    { "value": "Bahrain", "label": "Bahrain" },
    { "value": "Bangladesh", "label": "Bangladesh" },
    { "value": "Barbados", "label": "Barbados" },
    { "value": "Belarus", "label": "Belarus" },
    { "value": "Belgium", "label": "Belgium" },
    { "value": "Belize", "label": "Belize" },
    { "value": "Benin", "label": "Benin" },
    { "value": "Bermuda", "label": "Bermuda" },
    { "value": "Bhutan", "label": "Bhutan" },
    { "value": "Bolivia", "label": "Bolivia" },
    { "value": "Bosnia and Herzegovina", "label": "Bosnia and Herzegovina" },
    { "value": "Botswana", "label": "Botswana" },
    { "value": "Bouvet Island", "label": "Bouvet Island" },
    { "value": "Brazil", "label": "Brazil" },
    { "value": "British Indian Ocean Territory", "label": "British Indian Ocean Territory" },
    { "value": "Brunei Darussalam", "label": "Brunei Darussalam" },
    { "value": "Bulgaria", "label": "Bulgaria" },
    { "value": "Burkina Faso", "label": "Burkina Faso" },
    { "value": "Burundi", "label": "Burundi" },
    { "value": "Cambodia", "label": "Cambodia" },
    { "value": "Cameroon", "label": "Cameroon" },
    { "value": "Canada", "label": "Canada" },
    { "value": "Cape Verde", "label": "Cape Verde" },
    { "value": "Cayman Islands", "label": "Cayman Islands" },
    { "value": "Central African Republic", "label": "Central African Republic" },
    { "value": "Chad", "label": "Chad" },
    { "value": "Chile", "label": "Chile" },
    { "value": "China", "label": "China" },
    { "value": "Christmas Island", "label": "Christmas Island" },
    { "value": "Cocos (Keeling) Islands", "label": "Cocos (Keeling) Islands" },
    { "value": "Colombia", "label": "Colombia" },
    { "value": "Comoros", "label": "Comoros" },
    { "value": "Congo", "label": "Congo" },
    { "value": "Cook Islands", "label": "Cook Islands" },
    { "value": "Costa Rica", "label": "Costa Rica" },
    { "value": "Cote D'Ivoire", "label": "Cote D'Ivoire" },
    { "value": "Croatia", "label": "Croatia" },
    { "value": "Cuba", "label": "Cuba" },
    { "value": "Cyprus", "label": "Cyprus" },
    { "value": "Czech Republic", "label": "Czech Republic" },
    { "value": "Denmark", "label": "Denmark" },
    { "value": "Djibouti", "label": "Djibouti" },
    { "value": "Dominica", "label": "Dominica" },
    { "value": "Dominican Republic", "label": "Dominican Republic" },
    { "value": "Ecuador", "label": "Ecuador" },
    { "value": "Egypt", "label": "Egypt" },
    { "value": "El Salvador", "label": "El Salvador" },
    { "value": "Equatorial Guinea", "label": "Equatorial Guinea" },
    { "value": "Eritrea", "label": "Eritrea" },
    { "value": "Estonia", "label": "Estonia" },
    { "value": "Ethiopia", "label": "Ethiopia" },
    { "value": "Falkland Islands (Malvinas)", "label": "Falkland Islands (Malvinas)" },
    { "value": "Faroe Islands", "label": "Faroe Islands" },
    { "value": "Fiji", "label": "Fiji" },
    { "value": "Finland", "label": "Finland" },
    { "value": "France", "label": "France" },
    { "value": "French Guiana", "label": "French Guiana" },
    { "value": "French Polynesia", "label": "French Polynesia" },
    { "value": "French Southern Territories", "label": "French Southern Territories" },
    { "value": "Gabon", "label": "Gabon" },
    { "value": "Gambia", "label": "Gambia" },
    { "value": "Georgia", "label": "Georgia" },
    { "value": "Germany", "label": "Germany" },
    { "value": "Gibraltar", "label": "Gibraltar" },
    { "value": "Greece", "label": "Greece" },
    { "value": "Greenland", "label": "Greenland" },
    { "value": "Grenada", "label": "Grenada" },
    { "value": "Guadeloupe", "label": "Guadeloupe" },
    { "value": "Guam", "label": "Guam" },
    { "value": "Guatemala", "label": "Guatemala" },
    { "value": "Guernsey", "label": "Guernsey" },
    { "value": "Guinea", "label": "Guinea" },
    { "value": "Guinea-Bissau", "label": "Guinea-Bissau" },
    { "value": "Guyana", "label": "Guyana" },
    { "value": "Haiti", "label": "Haiti" },
    { "value": "Heard Island and Mcdonald Islands", "label": "Heard Island and Mcdonald Islands" },
    { "value": "Holy See (Vatican City State)", "label": "Holy See (Vatican City State)" },
    { "value": "Honduras", "label": "Honduras" },
    { "value": "Hong Kong", "label": "Hong Kong" },
    { "value": "Hungary", "label": "Hungary" },
    { "value": "Iceland", "label": "Iceland" },
    { "value": "India", "label": "India" },
    { "value": "Indonesia", "label": "Indonesia" },
    { "value": "Iran", "label": "Iran" },
    { "value": "Iraq", "label": "Iraq" },
    { "value": "Ireland", "label": "Ireland" },
    { "value": "Isle of Man", "label": "Isle of Man" },
    { "value": "Israel", "label": "Israel" },
    { "value": "Italy", "label": "Italy" },
    { "value": "Jamaica", "label": "Jamaica" },
    { "value": "Japan", "label": "Japan" },
    { "value": "Jersey", "label": "Jersey" },
    { "value": "Jordan", "label": "Jordan" },
    { "value": "Kazakhstan", "label": "Kazakhstan" },
    { "value": "Kenya", "label": "Kenya" },
    { "value": "Kiribati", "label": "Kiribati" },
    { "value": "Korea", "label": "Korea" },
    { "value": "Kuwait", "label": "Kuwait" },
    { "value": "Kyrgyzstan", "label": "Kyrgyzstan" },
    { "value": "Lao People's Democratic Republic", "label": "Lao People's Democratic Republic" },
    { "value": "Latvia", "label": "Latvia" },
    { "value": "Lebanon", "label": "Lebanon" },
    { "value": "Lesotho", "label": "Lesotho" },
    { "value": "Liberia", "label": "Liberia" },
    { "value": "Libyan Arab Jamahiriya", "label": "Libyan Arab Jamahiriya" },
    { "value": "Liechtenstein", "label": "Liechtenstein" },
    { "value": "Lithuania", "label": "Lithuania" },
    { "value": "Luxembourg", "label": "Luxembourg" },
    { "value": "Macao", "label": "Macao" },
    { "value": "Macedonia", "label": "Macedonia" },
    { "value": "Madagascar", "label": "Madagascar" },
    { "value": "Malawi", "label": "Malawi" },
    { "value": "Malaysia", "label": "Malaysia" },
    { "value": "Maldives", "label": "Maldives" },
    { "value": "Mali", "label": "Mali" },
    { "value": "Malta", "label": "Malta" },
    { "value": "Marshall Islands", "label": "Marshall Islands" },
    { "value": "Martinique", "label": "Martinique" },
    { "value": "Mauritania", "label": "Mauritania" },
    { "value": "Mauritius", "label": "Mauritius" },
    { "value": "Mayotte", "label": "Mayotte" },
    { "value": "Mexico", "label": "Mexico" },
    { "value": "Micronesia", "label": "Micronesia" },
    { "value": "Moldova", "label": "Moldova" },
    { "value": "Monaco", "label": "Monaco" },
    { "value": "Mongolia", "label": "Mongolia" },
    { "value": "Montserrat", "label": "Montserrat" },
    { "value": "Morocco", "label": "Morocco" },
    { "value": "Mozambique", "label": "Mozambique" },
    { "value": "Myanmar", "label": "Myanmar" },
    { "value": "Namibia", "label": "Namibia" },
    { "value": "Nauru", "label": "Nauru" },
    { "value": "Nepal", "label": "Nepal" },
    { "value": "Netherlands", "label": "Netherlands" },
    { "value": "Netherlands Antilles", "label": "Netherlands Antilles" },
    { "value": "New Caledonia", "label": "New Caledonia" },
    { "value": "New Zealand", "label": "New Zealand" },
    { "value": "Nicaragua", "label": "Nicaragua" },
    { "value": "Niger", "label": "Niger" },
    { "value": "Nigeria", "label": "Nigeria" },
    { "value": "Niue", "label": "Niue" },
    { "value": "Norfolk Island", "label": "Norfolk Island" },
    { "value": "Northern Mariana Islands", "label": "Northern Mariana Islands" },
    { "value": "Norway", "label": "Norway" },
    { "value": "Oman", "label": "Oman" },
    { "value": "Pakistan", "label": "Pakistan" },
    { "value": "Palau", "label": "Palau" },
    { "value": "Palestinian Territory", "label": "Palestinian Territory" },
    { "value": "Panama", "label": "Panama" },
    { "value": "Papua New Guinea", "label": "Papua New Guinea" },
    { "value": "Paraguay", "label": "Paraguay" },
    { "value": "Peru", "label": "Peru" },
    { "value": "Philippines", "label": "Philippines" },
    { "value": "Pitcairn", "label": "Pitcairn" },
    { "value": "Poland", "label": "Poland" },
    { "value": "Portugal", "label": "Portugal" },
    { "value": "Puerto Rico", "label": "Puerto Rico" },
    { "value": "Qatar", "label": "Qatar" },
    { "value": "Reunion", "label": "Reunion" },
    { "value": "Romania", "label": "Romania" },
    { "value": "Russian Federation", "label": "Russian Federation" },
    { "value": "RWANDA", "label": "RWANDA" },
    { "value": "Saint Helena", "label": "Saint Helena" },
    { "value": "Saint Kitts and Nevis", "label": "Saint Kitts and Nevis" },
    { "value": "Saint Lucia", "label": "Saint Lucia" },
    { "value": "Saint Pierre and Miquelon", "label": "Saint Pierre and Miquelon" },
    { "value": "Saint Vincent and the Grenadines", "label": "Saint Vincent and the Grenadines" },
    { "value": "Samoa", "label": "Samoa" },
    { "value": "San Marino", "label": "San Marino" },
    { "value": "Sao Tome and Principe", "label": "Sao Tome and Principe" },
    { "value": "Saudi Arabia", "label": "Saudi Arabia" },
    { "value": "Senegal", "label": "Senegal" },
    { "value": "Serbia and Montenegro", "label": "Serbia and Montenegro" },
    { "value": "Seychelles", "label": "Seychelles" },
    { "value": "Sierra Leone", "label": "Sierra Leone" },
    { "value": "Singapore", "label": "Singapore" },
    { "value": "Slovakia", "label": "Slovakia" },
    { "value": "Slovenia", "label": "Slovenia" },
    { "value": "Solomon Islands", "label": "Solomon Islands" },
    { "value": "Somalia", "label": "Somalia" },
    { "value": "South Africa", "label": "South Africa" },
    { "value": "South Georgia and the South Sandwich Islands", "label": "South Georgia and the South Sandwich Islands" },
    { "value": "Spain", "label": "Spain" },
    { "value": "Sri Lanka", "label": "Sri Lanka" },
    { "value": "Sudan", "label": "Sudan" },
    { "value": "Suriname", "label": "Suriname" },
    { "value": "Svalbard and Jan Mayen", "label": "Svalbard and Jan Mayen" },
    { "value": "Swaziland", "label": "Swaziland" },
    { "value": "Sweden", "label": "Sweden" },
    { "value": "Switzerland", "label": "Switzerland" },
    { "value": "Syrian Arab Republic", "label": "Syrian Arab Republic" },
    { "value": "Taiwan", "label": "Taiwan" },
    { "value": "Tajikistan", "label": "Tajikistan" },
    { "value": "Tanzania", "label": "Tanzania" },
    { "value": "Thailand", "label": "Thailand" },
    { "value": "Timor-Leste", "label": "Timor-Leste" },
    { "value": "Togo", "label": "Togo" },
    { "value": "Tokelau", "label": "Tokelau" },
    { "value": "Tonga", "label": "Tonga" },
    { "value": "Trinidad and Tobago", "label": "Trinidad and Tobago" },
    { "value": "Tunisia", "label": "Tunisia" },
    { "value": "Turkey", "label": "Turkey" },
    { "value": "Turkmenistan", "label": "Turkmenistan" },
    { "value": "Turks and Caicos Islands", "label": "Turks and Caicos Islands" },
    { "value": "Tuvalu", "label": "Tuvalu" },
    { "value": "Uganda", "label": "Uganda" },
    { "value": "Ukraine", "label": "Ukraine" },
    { "value": "United Arab Emirates", "label": "United Arab Emirates" },
    { "value": "United Kingdom", "label": "United Kingdom" },
    { "value": "United States", "label": "United States" },
    { "value": "United States Minor Outlying Islands", "label": "United States Minor Outlying Islands" },
    { "value": "Uruguay", "label": "Uruguay" },
    { "value": "Uzbekistan", "label": "Uzbekistan" },
    { "value": "Vanuatu", "label": "Vanuatu" },
    { "value": "Venezuela", "label": "Venezuela" },
    { "value": "Viet Nam", "label": "Viet Nam" },
    { "value": "Virgin Islands", "label": "Virgin Islands" },
    { "value": "Virgin Islands", "label": "Virgin Islands" },
    { "value": "Wallis and Futuna", "label": "Wallis and Futuna" },
    { "value": "Western Sahara", "label": "Western Sahara" },
    { "value": "Yemen", "label": "Yemen" },
    { "value": "Zambia", "label": "Zambia" },
    { "value": "Zimbabwe", "label": "Zimbabwe" }
  ],

  exportFormats: {
    print: {
      label: 'Print',
      icon: 'pi pi-print',
      type: 'print',
      ext: '',
    },
    pdf: {
      label: 'Pdf',

      icon: 'pi pi-file-pdf',
      type: 'pdf',
      ext: 'pdf',
    },
    excel: {
      label: 'Excel',
      icon: 'pi pi-file-excel',
      type: 'excel',
      ext: 'xlsx',
    },
    csv: {
      label: 'Csv',
      icon: 'pi pi-table',
      type: 'csv',
      ext: 'csv',
    },
  },

}