<template>
    <div v-if="status == 'loading'" class="loading text-center flex align-items-center justify-content-center nice-shadow-6 surface-100" v-bind="$attrs">
        <ProgressSpinner style="width:30px;height:30px" />
    </div>

    <div v-if="status == 'error'" v-bind="$attrs"
        class="flex align-items-center justify-content-center text-center nice-shadow-6 surface-100 p-2  font-bold text-400 text-2xl">
        !
    </div>

    <img v-show="status == 'loaded'" :src="src" v-bind="$attrs" @load="loaded" @error="error">

</template>
<script>
export default {
    props: {
        src: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            status: 'loading',
        };
    },
    methods: {
        loaded() {
            this.status = 'loaded';
        },
        error() {
            this.status = 'error';
        },
    },
};
</script>