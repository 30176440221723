
import { createRouter, createWebHashHistory } from 'vue-router';
export default ({ store, auth }) => {
	let routes = [
		//audits routes
		{
			path: '/audits/:index?/:fieldName?/:fieldValue?',
			name: 'auditslist',
			component: () => import('./pages/audits/list.vue'),
			props: true
		},
		{
			path: '/audits/view/:id',
			name: 'auditsview',
			component: () => import('./pages/audits/view.vue'),
			props: true
		},

		//info routes
		{
			path: '/info/view/:id',
			name: 'infoview',
			component: () => import('./pages/info/view.vue'),
			props: true
		},
		{
			path: '/info/instructions/:id',
			name: 'infoinstructions',
			component: () => import('./pages/info/instructions.vue'),
			props: true
		},
		{
			path: '/info/edit/:id',
			name: 'infoedit',
			component: () => import('./pages/info/edit.vue'),
			props: true
		},
		{
			path: '/info/terms/:id',
			name: 'infoterms',
			component: () => import('./pages/info/terms.vue'),
			props: true
		},


		//notifications routes
		{
			path: '/notifications/:index?/:fieldName?/:fieldValue?',
			name: 'notificationslist',
			component: () => import('./pages/notifications/list.vue'),
			props: true
		},
		{
			path: '/notifications/view/:id',
			name: 'notificationsview',
			component: () => import('./pages/notifications/view.vue'),
			props: true
		},
		{
			path: '/notifications/add',
			name: 'notificationsadd',
			component: () => import('./pages/notifications/add.vue'),
			props: true
		},
		{
			path: '/notifications/edit/:id',
			name: 'notificationsedit',
			component: () => import('./pages/notifications/edit.vue'),
			props: true
		},
		{
			path: '/notifications/active/:index?/:fieldName?/:fieldValue?',
			name: 'notificationsactive',
			component: () => import('./pages/notifications/active.vue'),
			props: true
		},



		//rooms routes
		{
			path: '/rooms/:index?/:fieldName?/:fieldValue?',
			name: 'roomslist',
			component: () => import('./pages/rooms/list.vue'),
			props: true
		},
		{
			path: '/rooms/view/:id',
			name: 'roomsview',
			component: () => import('./pages/rooms/view.vue'),
			props: true
		},
		{
			path: '/rooms/add',
			name: 'roomsadd',
			component: () => import('./pages/rooms/add.vue'),
			props: true
		},
		{
			path: '/rooms/edit/:id',
			name: 'roomsedit',
			component: () => import('./pages/rooms/edit.vue'),
			props: true
		},
		{
			path: '/rooms/categories/:index?/:fieldName?/:fieldValue?',
			name: 'roomscategories',
			component: () => import('./pages/rooms/categories.vue'),
			props: true
		},
		{
			path: '/rooms/details/:id',
			name: 'roomsdetails',
			component: () => import('./pages/rooms/details.vue'),
			props: true
		},


		//transactions routes
		{
			path: '/transactions/:index?/:fieldName?/:fieldValue?',
			name: 'transactionslist',
			component: () => import('./pages/transactions/list.vue'),
			props: true
		},
		{
			path: '/transactions/view/:id',
			name: 'transactionsview',
			component: () => import('./pages/transactions/view.vue'),
			props: true
		},
		{
			path: '/transactions/add',
			name: 'transactionsadd',
			component: () => import('./pages/transactions/add.vue'),
			props: true
		},


		//users routes
		{
			path: '/users/:index?/:fieldName?/:fieldValue?',
			name: 'userslist',
			component: () => import('./pages/users/list.vue'),
			props: true
		},
		{
			path: '/users/view/:id',
			name: 'usersview',
			component: () => import('./pages/users/view.vue'),
			props: true
		},
		{
			path: '/index/login',
			name: 'usersuserlogin',
			component: () => import('./pages/index/userlogin.vue'),
			props: true
		},
		{
			path: '/index/register',
			name: 'usersuserregister',
			component: () => import('./pages/index/userregister.vue'),
			props: true
		},
		{
			path: '/account/edit',
			name: 'usersaccountedit',
			component: () => import('./pages/account/accountedit.vue'),
			props: true
		},
		{
			path: '/account',
			name: 'usersaccountview',
			component: () => import('./pages/account/accountview.vue'),
			props: true
		},

		{
			path: '/users/add',
			name: 'usersadd',
			component: () => import('./pages/users/add.vue'),
			props: true
		},

		{
			path: '/users/edit/:id',
			name: 'usersedit',
			component: () => import('./pages/users/edit.vue'),
			props: true
		},

		{
			path: '/users/studentview/:id',
			name: 'usersstudentview',
			component: () => import('./pages/users/studentview.vue'),
			props: true
		},

		{
			path: '/users/bookings/:index?/:fieldName?/:fieldValue?',
			name: 'usersbookings',
			component: () => import('./pages/users/bookings.vue'),
			props: true
		},


		{
			path: '/users/bookingedit/:id',
			name: 'usersbookingedit',
			component: () => import('./pages/users/bookingedit.vue'),
			props: true
		},

		{
			path: '/users/approvedbookings/:index?/:fieldName?/:fieldValue?',
			name: 'usersapprovedbookings',
			component: () => import('./pages/users/approvedbookings.vue'),
			props: true
		},


		{
			path: '/users/allocateroom/:id',
			name: 'usersallocateroom',
			component: () => import('./pages/users/allocateroom.vue'),
			props: true
		},

		{
			path: '/users/roomallocations/:index?/:fieldName?/:fieldValue?',
			name: 'usersroomallocations',
			component: () => import('./pages/users/roomallocations.vue'),
			props: true
		},

		//Otp verify code routes
		{
			path: '/index/verifyotp',
			name: 'verifyotp',
			component: () => import('./pages/index/verifyotp.vue'),
			props: true
		},

		//Password reset routes
		{
			path: '/index/forgotpassword',
			name: 'forgotpassword',
			component: () => import('./pages/index/forgotpassword.vue'),
			props: true
		},
		{
			path: '/index/resetpassword',
			name: 'resetpassword',
			component: () => import('./pages/index/resetpassword.vue'),
			props: true
		},
		{
			path: '/index/resetpassword_completed',
			name: 'resetpassword_completed',
			component: () => import('./pages/index/resetpassword_completed.vue'),
			props: true
		},

		// Custom routes
		{
			path: '/custom/contact',
			name: 'customcontact',
			component: () => import('./pages/custom/contact.vue'),
			props: true
		},


		//Email verify routes
		{
			path: '/index/verifyemail',
			name: 'verifyemail',
			component: () => import('./pages/index/verifyemail.vue'),
			props: true
		},
		{
			path: '/index/emailverified',
			name: 'emailverified',
			component: () => import('./pages/index/emailverified.vue'),
			props: true
		},
		{
			path: '/index/accountblocked',
			name: 'accountblocked',
			component: () => import('./pages/index/accountblocked.vue'),
			props: true
		},

		{
			path: '/error/forbidden',
			name: 'forbidden',
			component: () => import('./pages/errors/forbidden.vue'),
			props: true
		},
		{
			path: '/error/notfound',
			name: 'notfound',
			component: () => import('./pages/errors/pagenotfound.vue'),
			props: true
		},
		{
			path: '/:catchAll(.*)',
			component: () => import('./pages/errors/pagenotfound.vue')
		}
	];

	const user = store.state.auth.user;
	if (user) {
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else {
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}


	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion) {
			//if(savedPostion) return savedPostion;
			return { x: 0, y: 0 }
		}
	});


	router.beforeEach((to, from, next) => {
		const user = store.state.auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/', query: { nexturl: to.fullPath } });
		}

		if (user && to.name == "index") {
			//already logged in, show home when try to access index page
			return next({ path: "/home" });
		}

		if (user && to.path == "/index/login") {
			//already logged in, show home when try to access index page
			return next({ path: "/home" });
		}

		//navigate to redirect url if available
		if (to.name == "home" && to.query.nexturl) {
			return next({ path: to.query.nexturl });
		}

		//close dialog from previous page
		store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
