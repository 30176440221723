<template>
	<div :class="containerClass" @click="onWrapperClick">
		<template v-if="$auth.isLoggedIn">
			<header class="relative z-50 w-full h-24">
				<div
					class="flex flex-grow-1 justify-content-between h-full max-w-6xl px-2 mx-auto sm:justify-between xl:px-0">
					<div>
						<router-link to="/">
							<Button class="p-button-link p-0 font-bold text-black">
								<img src="images/logo.png" alt="logo"
									style="width: 100px; border-radius: 50%; overflow: hidden" class="" />
								<span class="hidden-xs hidden-sm  sm:text-lg xl:text-2xl text-gray-800"> {{ $appName
									}}<span class="text-pink-500">.</span></span>
							</Button>
						</router-link>
					</div>
					<div class="mr-3 mt-5">
						<Button @click="startLogout()" label="Sign Out" class="p-button-sm p-button-danger px-5"
							icon="pi pi-power-off"></Button>
					</div>
				</div>
			</header>
		</template>

		<template v-else>
			<header class="relative z-50 w-full h-24">
				<div
					class="flex flex-grow-1 justify-content-between h-full max-w-6xl px-2 mx-auto sm:justify-between xl:px-0">
					<div>
						<router-link to="/">
							<Button class="p-button-link p-0 font-bold text-black">
								<img src="images/logo.png" alt="logo"
									style="width: 100px; border-radius: 50%; overflow: hidden" class="" />
								<span class="hidden-xs hidden-sm  sm:text-lg xl:text-2xl text-gray-800"> {{ $appName
									}}<span class="text-pink-500">.</span></span>
							</Button>
						</router-link>
					</div>
					<div class="mr-3 mt-5">
						<router-link :to="`/index/login`">
							<Button label="Get Started"
								class="p-button-md p-button-outlined p-button-danger px-5"></Button>
						</router-link>
					</div>
				</div>
			</header>
		</template>

		<div
			class="layout-main-container flex flex-grow-1 justify-content-between h-full max-w-6xl px-4 mx-auto sm:justify-between xl:px-0">
			<div class="layout-main">
				<router-view />
			</div>
		</div>

		<footer class="pt-12 pb-5 text-white bg-white border-t border-gray-200">
			<div class="container flex flex-col justify-between max-w-6xl px-4 mx-auto overflow-hidden lg:flex-row">
				<div class="w-full pl-5 mr-4 text-left lg:w-1/4 sm:text-center sm:pl-0 lg:text-left">
					<img src="images/logo.png" alt="logo" style="width: 50px; border-radius: 50%; overflow: hidden"
						class="" />
					<p class="mr-4 text-xs text-gray-500">
						© 2023 {{ $appName }}. ALL RIGHTS RESERVED.
					</p>
				</div>
				<div class="block w-full pl-10 mt-6 text-sm lg:w-3/4 sm:flex lg:mt-0">
					<ul class="flex flex-col w-full p-0 font-medium text-left text-gray-700 list-none">
						<li v-if="$auth.isLoggedIn">
							<router-link :to="`/rooms/categories`">
							<span class="inline-block py-2 text-gray-500 no-underline hover:text-gray-600">Find a
								Room</span>
							</router-link>
						</li>
						<li >
							<a href="https://www.google.com/maps/dir//6.6705589,-1.5600629/@6.6705589,-1.5600629,16z" target="_blank" class="inline-block py-2 text-gray-500 no-underline hover:text-gray-600">Location</a>
						</li>
						<li>
							<a :href="$apiUrl + `docs/Tenancy_Agreement.pdf`"  target="_blank" class="inline-block py-2 text-gray-500 no-underline hover:text-gray-600">
								Tenancy Agreement</a>
						</li>
					</ul>
					<div class="flex flex-col w-full text-gray-700">
						<div class="inline-block py-2 mt-5 font-bold text-gray-800 uppercase md:mt-0">
							Contact Us
						</div>
						<ul class="flex flex-col w-full p-0 font-medium text-left text-gray-700 list-none">
							<li>
								Tel: 0544-975-461
							</li>
							<li class="mt-2">
								<b>Location:</b> <br> CLOSE TO AMEN HOSTEL, KOTEI- KUMASI
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>

		<!-- App right drawer -->
		<Sidebar :showCloseIcon="false" position="right" v-model:visible="showRightDrawer"
			:style="{ width: rightDrawer.width }">
			<component v-if="showRightDrawer" is-sub-page :is="drawerComponentFile" :api-path="rightDrawer.pageUrl"
				:page-data="rightDrawer.pageData" />
		</Sidebar>

		<transition name="layout-mask">
			<div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
		</transition>

		<!-- Page display dialog -->
		<Dialog class="card py-4 px-0" :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
			:style="{ width: pageDialog.width, maxWidth: pageDialog.maxWidth }" :modal="!pageDialog.seamless"
			:maximized="pageDialog.maximized" :dismissableMask="!pageDialog.persistent" :position="pageDialog.position"
			v-model:visible="showPageDialog" :showHeader="false">
			<Button v-if="pageDialog.closeBtn" @click="showPageDialog = false"
				style="position: absolute; right: 15px; top: 5px" icon="pi pi-times"
				class="p-button-rounded p-button-text p-button-plain" />

			<component v-if="pageDialog.showDialog" is-sub-page :is="dialogComponentFile" :api-path="pageDialog.pageUrl"
				:page-data="pageDialog.pageData" />
		</Dialog>

		<!-- image preview dialog-->
		<Dialog class="card p-0" header="..." :showHeader="true" :breakpoints="{ '960px': '40vw', '640px': '95vw' }"
			style="width: auto" v-model:visible="showImageDialog" dismissableMask modal>
			<div class="text-center">
				<Carousel v-if="imageDialog.images.length > 1" :value="imageDialog.images" :circular="true"
					:page="imageDialog.currentSlide">
					<template #item="{ data }">
						<div style="margin: auto;" class="text-center">
							<NiceImage style="margin: auto; max-width: 100%; max-height: 70vh" :src="data" />
						</div>
					</template>
				</Carousel>
				<NiceImage v-else style="margin: auto; max-width: 100%; max-height: 70vh" :src="imageDialog.images[0]" />
			</div>
		</Dialog>

		<!-- request error dialog -->
		<Dialog class="card p-2" modal v-model:visible="errorDialog" :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
			style="width: 30vw" position="top">
			<template #header>
				<div class="flex align-items-center">
					<div class="mr-3">
						<Avatar size="large" class="bg-pink-100 text-pink-600" icon="pi pi-exclamation-triangle" />
					</div>
				</div>
			</template>

			<div class="text-pink-600 text-lg" v-for="(msg, index) in pageErrors" :key="index">
				{{ msg }}
			</div>
		</Dialog>

		<!-- app confirm page dialog -->
		<ConfirmDialog></ConfirmDialog>

		<!-- app flash message toast -->
		<Toast position="top-center" />

		<!-- app fullscreen loading dialog -->
		<Dialog class="card m-0 text-center" :showHeader="false" modal v-model:visible="fullScreenLoading"
			:breakpoints="{ '960px': '50vw', '640px': '95vw' }" style="width: 20vw" position="center">
			<ProgressSpinner style="width: 60px" />
			<hr />
			{{ fullScreenLoadingMsg }}
		</Dialog>
	</div>
</template>
<script setup>
	import { defineAsyncComponent, ref, computed } from "vue";
	import SideNavMenu from "@/components/SideNavMenu.vue";
	import { useApp } from "@/composables/app";
	import { useAuth } from "@/composables/auth";
	import { useRoute, useRouter } from "vue-router";

	import { ApiService } from "@/services/api";
	import { useStore } from "vuex";

	const app = useApp();
	const auth = useAuth();
	const store = useStore();
	const route = useRoute();
	const router = useRouter();

	const pageDialog = computed(() => store.state.app.pageDialog);
	const imageDialog = computed(() => store.state.app.imageDialog);
	const rightDrawer = computed(() => store.state.app.rightDrawer);

	const showPageDialog = computed({
		get() {
			return pageDialog.value.showDialog;
		},
		set(value) {
			store.dispatch("app/closePageDialog");
		},
	});

	const showImageDialog = computed({
		get() {
			return imageDialog.value.showDialog;
		},
		set(value) {
			store.dispatch("app/closeImageDialog");
		},
	});

	const showRightDrawer = computed({
		get() {
			return rightDrawer.value.showDrawer;
		},
		set(value) {
			store.dispatch("app/closeRightDrawer");
		},
	});

	const pageErrors = computed({
		get() {
			return store.state.app.pageErrors;
		},
		set(value) {
			store.commit("app/setPageErrors", value);
		},
	});

	const errorDialog = computed({
		get() {
			return pageErrors.value.length > 0;
		},
		set(newValue) {
			pageErrors.value = [];
		},
	});

	const fullScreenLoading = computed(() => store.state.app.fullScreenLoading);
	const fullScreenLoadingMsg = computed(
		() => store.state.app.fullScreenLoadingMsg
	);

	const dialogComponentFile = computed(() => {
		const dialog = pageDialog.value;
		if (dialog.showDialog && dialog.pageComponent) {
			return defineAsyncComponent(() =>
				import(`@/pages/${dialog.pageComponent}.vue`)
			);
		}
		return null;
	});

	const drawerComponentFile = computed(() => {
		const drawer = rightDrawer.value;
		if (drawer.showDrawer && drawer.pageComponent) {
			return defineAsyncComponent(() =>
				import(`@/pages/${drawer.pageComponent}.vue`)
			);
		}
		return null;
	});

	const layoutMode = ref("static");
	const menuClick = ref(false);
	const staticMenuInactive = ref(false);
	const overlayMenuActive = ref(false);
	const mobileMenuActive = ref(false);

	const containerClass = computed(() => {
		if (!auth.isLoggedIn) {
			staticMenuInactive.value = true;
			mobileMenuActive.value = false;
		}

		return [
			"layout-wrapper",
			{
				"layout-overlay": layoutMode.value === "overlay",
				"layout-static": layoutMode.value === "static",
				"layout-static-sidebar-inactive":
					staticMenuInactive.value && layoutMode.value === "static",
				"layout-overlay-sidebar-active":
					overlayMenuActive.value && layoutMode.value === "overlay",
				"layout-mobile-sidebar-active": mobileMenuActive.value,
				"p-input-filled": false,
			},
		];
	});

	const navbarSideLeftItems = app.menus.navbarSideLeftItems;

	const navbarTopLeftItems = app.menus.navbarTopLeftItems;

	const navbarTopRightItems = app.menus.navbarTopRightItems;

	function onMenuItemClick(event) {
		if (event.item && !event.item.items) {
			overlayMenuActive.value = false;
			mobileMenuActive.value = false;
		}
	}

	function onWrapperClick() {
		if (!menuClick.value) {
			overlayMenuActive.value = false;
			mobileMenuActive.value = false;
		}
		menuClick.value = false;
	}

	function onMenuToggle() {
		menuClick.value = true;
		if (app.isDesktop()) {
			if (layoutMode.value === "overlay") {
				if (mobileMenuActive.value === true) {
					overlayMenuActive.value = true;
				}
				overlayMenuActive.value = !overlayMenuActive.value;
				mobileMenuActive.value = false;
			} else if (layoutMode.value === "static") {
				staticMenuInactive.value = !staticMenuInactive.value;
			}
		} else {
			mobileMenuActive.value = !mobileMenuActive.value;
		}
	}

	const appBarActionMenu = [
		{
			to: "/account",
			label: "My Account",
			icon: "pi pi-user",
		},
		{
			label: "Logout",
			icon: "pi pi-window-minimize",
			command: (event) => {
				startLogout();
			},
		},
	];

	function startLogout() {
		auth.logout();
		location.href = "/"; //reload page and navigated to index page
	}

	function initAxioInterceptors() {
		// Add a request interceptor
		ApiService.axios().interceptors.request.use(
			(config) => {
				//before new request, hide previous error message
				pageErrors.value = [];
				return config;
			},
			(error) => {
				// Do something with request error
				return Promise.reject(error);
			}
		);
	}
	initAxioInterceptors();
</script>
<style>
	.layout-main-container {
		padding: 2rem 2rem 2rem 2rem;
		min-height: 50vh;
	}
	.p-carousel-item .p-carousel-item-active .p-carousel-item-start .p-carousel-item-end{
		margin: auto
	}
</style>